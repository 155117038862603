







































































.video_actions {
  position: fixed;
  bottom: 8%;
  padding: 0;
  right: 4%;
  left: auto;
  width: 20%;
  height: 5.3%;
}

.video-bar {
  position: absolute;
  top: 0;
  width: 0%;
  height: 7px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  -moz-transition: width 1.5s;
  -webkit-transition: width 1.5s;
  -o-transition: width 1.5s;
  transition: width 1.5s;
}

.video-bar:hover {
  height: 10px;
}
